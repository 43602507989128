@import "../../../assets/styles/base/mixins";

.toast-message {
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  border-radius: 8px;
  max-width: 800px;
  min-width: 350px;
  width: fit-content;
  transition: 0.5s ease;
  position: fixed;
  z-index: 2000;
  top: 50px;
  right: 50px;
  padding: 0px 20px;
  align-items: center;
  box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px;
  @include respond-to("tablet") {
    left: 5%;
    width: 90%;
  }

  .close-toast {
    cursor: pointer;
  }

  &.success {
    background: #19ae57;
  }

  &.warning {
    background: #fd561f;
  }

  &.hint {
    background: yellowgreen;
  }
}
